// =====================================================
//
// main Style
//
// =====================================================

// main
// ================================== //
.l-main {

  .l-section {
    + .l-section {
    }// + .l-section
  }// .l-section

  @include mq(sp, max, true) {

    flex-basis: 100%;

    .l-section {
      + .l-section {
        margin-top: 2em;
      }// + .l-section
    }// .l-section

  }// @include mq(sp, max, true)
  @include mq(tab) {

    flex: 3;

    .l-section {
      + .l-section {
        margin-top: 2.5em;
      }// + .l-section
    }// .l-section

  }// @include mq(tab)
  @include mq(pc) {

    .l-section {
      + .l-section {
        margin-top: 3em;
      }// + .l-section
    }// .l-section

  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .l-main
