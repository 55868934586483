// =====================================================
//
// Footer Style
//
// =====================================================

// footer
// ================================== //
.l-footer {

  background-color: $color_black;
  margin-top: $ct_gutter * 4;
  padding-right: $ct_gutter * 2;
  padding-left: $ct_gutter * 2;
  color: $color_white;

  .l-inner {
    max-width: $site_width;
    margin-right: auto;
    margin-left: auto;
    padding-top: $ct_gutter;
  }// .l-inner

  .sns {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    .sns_item {
      margin: 0 $ct_gutter_half;
    }// .sns_item
    .sns_link {
      display: block;
      color: $color_white;
      text-decoration: none;
      font-size: fs(26);
    }// .sns_link
  }// .sns

  .copyright {
    padding: .5rem 0 1rem;
    text-align: center;
    small {
      font-size: fs(14);
    }// small
  }// .copyright

  @include mq(sp, max, true) {

    padding-right: $ct_gutter;
    padding-left: $ct_gutter;

  }// @include mq(sp, max, true)
  @include mq(tab) {
  }// @include mq(tab)
  @include mq(pc) {
  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .l-footer
