// =====================================================
//
// container Style
//
// =====================================================

// container
// ================================== //
.l-container {
  @include mq(sp, max, true) {
    padding: $ct_gutter_half;
  }// @include mq(sp, max, true)
  @include mq(tab) {
    padding: $ct_gutter;
  }// @include mq(tab)
  @include mq(pc) {
    padding: $ct_gutter * 2;
  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .l-container


// wrapper
// ================================== //
.l-wrap {
  @include mq(sp, max, true) {
    .l-main {
    }// .l-main
    .l-sub {
      margin-top: 2em;
    }// .l-sub
  }// @include mq(sp, max, true)
  @include mq(tab) {
    .l-main {
    }// .l-main
    .l-sub {
      margin-top: 2.5em;
    }// .l-sub
  }// @include mq(tab)
  @include mq(pc) {
    display: flex;
    justify-content: space-between;
    max-width: $site_width;
    margin-right: auto;
    margin-left: auto;
    .l-sub {
      margin-top: 0;
    }// .l-sub
    &.l-wrap-col1 {
      display: block;
      .l-main {
        width: 65%;
        margin-right: auto;
        margin-left: auto;
      }// .l-main
      .l-sub {
        width: 65%;
        margin-right: auto;
        margin-left: auto;
      }// .l-sub
    }// &.l-wrap-col2
    &.l-wrap-col2 {
      .l-main {
        width: 65%;
      }// .l-main
      .l-sub {
        width: 32%;
        margin-left: 3%;
      }// .l-sub
    }// &.l-wrap-col2
    &.l-wrap-col2-half {
      .l-main {
        width: 48.5%;
      }// .l-main
      .l-sub {
        width: 48.5%;
        margin-left: 3%;
      }// .l-sub
    }// &.l-wrap-col2-half
    &.l-wrap-col3 {
      .l-main {
        width: 38%;
      }// .l-main
      .l-sub {
        width: 30%;
        margin-left: 1%;
      }// .l-sub
    }// &.l-wrap-col3
  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .l-wrap


// inner
// ================================== //
.l-inner {

  max-width: $site_width;
  margin: 0 auto;

  @include mq(sp, max, true) {
  }// @include mq(sp, max, true)
  @include mq(tab) {
  }// @include mq(tab)
  @include mq(pc) {
  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .l-inner


// content
// ================================== //
.l-content {

  padding-right: $ct_gutter * 2;
  padding-left: $ct_gutter * 2;

  > .l-inner {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
  }// > .l-inner

  @include mq(sp, max, true) {

    padding-right: $ct_gutter;
    padding-left: $ct_gutter;

  }// @include mq(sp, max, true)
  @include mq(tab) {
  }// @include mq(tab)
  @include mq(pc) {
  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)

  // 内包1カラム
  &-wide {

    padding-right: $ct_gutter * 2;
    padding-left: $ct_gutter * 2;

    @include mq(sp, max, true) {

      padding-right: $ct_gutter;
      padding-left: $ct_gutter;

    }// @include mq(sp, max, true)
    @include mq(tab) {
    }// @include mq(tab)
    @include mq(pc) {
    }// @include mq(pc)
    @include mq(wide) {
    }// @include mq(wide)
  }// &-wide

}// .l-content
