//=====================================================
//
// Table Style
//
// =====================================================

// table - scroll
// ================================== //
.c-tbl_wrap {
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}// .c-tbl_wrap


// table - default
// ================================== //
.c-tbl {

  background-color: #CCC;
  border-collapse: separate;
  border-spacing: 1px;

  caption {
  }// caption

  tfoot {
  }// tfoot

  tr {
  }// tr

  th {
    background-color: #FFF;
    padding: $ct_gutter;
    text-align: left;
  }// th

  td {
    background-color: #FFF;
    padding: $ct_gutter;
  }// td

  .c-tbl_empty {
    @include tilt_bordder;
  }// .c-tbl_empty

  &-full {
    width: 100%;
  }// &-full

  &-fixed {
    table-layout: fixed;
  }// &-fixed

  &-stripe {
    tbody {
      tr:nth-child(odd) {
        th, td {
          background-color: #F7F7F7;
        }// th, td
      }// tr:nth-child(odd)
    }// tbody
  }// &-stripe

  &-noborder {
    border-collapse: collapse;
    border-spacing: none;
  }// &-noborder

  &-scroll {
    th, td {
      white-space: nowrap;
    }// th, td
  }// &-scroll

  @include mq(sp, max, true) {

    th {
      padding: $ct_gutter_half;
    }// th

    td {
      padding: $ct_gutter_half $ct_gutter_half $ct_gutter;
    }// td

    &.c-tbl-block {
      display: block;
      border: 1px solid #CCC;
      border-bottom: none;
      caption,
      thead,
      tbody,
      tfoot,
      tr,
      th,
      td {
        display: block;
      }// [class*="c-tbl_"]
      th {
      }// th
      caption {
        border-bottom: 1px solid #CCC;
      }// caption
      th {
        border-bottom: 1px solid #CCC;
      }// th
      td {
        border-bottom: 1px solid #CCC;
      }// td
      .c-tbl_empty {
        display: none;
      }// .c-tbl_empty
    }// &.c-tbl-block;

    &-scroll {
      min-width: 200%;
    }// &-scroll

  }// @include mq(sp, max, true)
  @include mq(tab) {
  }// @include mq(tab)
  @include mq(pc) {
  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .c-tbl
