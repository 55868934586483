//=====================================================
//
// Title Style
//
// =====================================================

// Primary
// ================================== //
.c-ttl-primary {
  font-size: fs(22);
  margin-bottom: .5em;
  @include mq(tab) {
    font-size: fs(24);
    margin-bottom: .675em;
  }// @include mq(tab)
  @include mq(pc) {
    font-size: fs(26);
    margin-bottom: .75em;
  }// @include mq(pc)
  @include mq(wide) {
    font-size: fs(28);
    margin-bottom: .875em;
  }// @include mq(wide)
}// .c-ttl-primary

// Secondary
// ================================== //
.c-ttl-secondary {
  font-size: fs(20);
  margin-bottom: .5em;
  @include mq(tab) {
    font-size: fs(22);
    margin-bottom: .675em;
  }// @include mq(tab)
  @include mq(pc) {
    font-size: fs(24);
    margin-bottom: .75em;
  }// @include mq(pc)
  @include mq(wide) {
    font-size: fs(26);
    margin-bottom: .875em;
  }// @include mq(wide)
}// .c-ttl-secondary

// Tertiary
// ================================== //
.c-ttl-tertiary {
  font-size: fs(18);
  margin-bottom: .5em;
  @include mq(tab) {
    font-size: fs(20);
    margin-bottom: .675em;
  }// @include mq(tab)
  @include mq(pc) {
    font-size: fs(22);
    margin-bottom: .75em;
  }// @include mq(pc)
  @include mq(wide) {
    font-size: fs(24);
    margin-bottom: .875em;
  }// @include mq(wide)
}// .c-ttl-tertiary
