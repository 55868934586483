@if $use_css_utility {
// -------------------------------------------
// Utility - text
// -------------------------------------------

  .u-indent { text-indent: 1em; }
  .u-del { text-decoration: line-through; }
  .u-italic { font-style: italic; }
  .u-normal { font-weight: normal; }
  .u-bold { font-weight: bold; }
  .u-xx_small { font-size: 60%; }
  .u-x_small { font-size: 75%; }
  .u-small { font-size: 88.88%; }
  .u-large { font-size: 120%; }
  .u-x_large { font-size: 150%; }
  .u-xx_large { font-size: 200%; }

}
