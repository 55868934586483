// =====================================================
//
// 色設定
//
// =====================================================

// Palette
// ================================== //
$color_red: #EF3724;
$color_orange: #F90;
$color_yellow: #FFD600;
$color_green: #28A745;
$color_teal: #66E4E6;
$color_blue: #3E75E1;
$color_indigo: #716FEA;
$color_purple: #9371E2;
$color_pink: #FE77A6;

$color_dark: #414A63;
$color_light: #EAEAEA;
$color_black: #252627;
$color_white: #FFF;


// Base
// ================================== //

// body background value
$body_bg_value: #FFF;

// main color (base color)
$main_color: $color_blue;

// sub color
$sub_color: $color_orange;

// accent color
$accent_color: $color_green;

// base text color
$font_color: #333;
$font_color_light: #666;
$font_color_exlight: #999;
$font_color_splight: #CCC;
$font_color_dark: #000;


// Links
// ================================== //

// base link color
$link_color_primary: $main_color;
$link_color_hover: $sub_color;
$link_color_active: $sub_color;
$link_color_visited: $color_purple;


// Buttons
// ================================== //

$btn_bg_color_default: #CCC;
$btn_bdr_color_default: #CCC;
$btn_txt_color_default: $font_color;

$btn_bg_color_primary: $color_blue;
$btn_bdr_color_primary: $color_blue;
$btn_txt_color_primary: $color_white;

$btn_bg_color_secondary: $color_orange;
$btn_bdr_color_secondary: $color_orange;
$btn_txt_color_secondary: $color_white;

$btn_bg_color_success: $color_green;
$btn_bdr_color_success: $color_green;
$btn_txt_color_success: $color_white;

$btn_bg_color_caution: $color_red;
$btn_bdr_color_caution: $color_red;
$btn_txt_color_caution: $color_white;

$btn_bg_color_warning: $color_yellow;
$btn_bdr_color_warning: $color_yellow;
$btn_txt_color_warning: $color_black;

$btn_bg_color_dark: $color_dark;
$btn_bdr_color_dark: $color_dark;
$btn_txt_color_dark: $color_white;

$btn_bg_color_black: $color_black;
$btn_bdr_color_black: $color_black;
$btn_txt_color_black: $color_white;

$btn_bg_color_link: transparent;
$btn_bdr_color_link: transparent;
$btn_txt_color_link: $link_color_primary;


// Tooltip
// ================================== //

$tooltip_bg_color: rgba(#000, .75);
$tooltip_txt_color: #FFF;


// Forms
// ================================== //

// チェックボックス＆ラジオボタン
$ui_input_bdr_color: #666;
$ui_input_bg_color: #FFF;
$ui_input_checked_color: $main_color;

// セレクトメニュー
$ui_select_bg_color: #FFF;
$ui_select_bdr_color: $font_color;
$ui_select_bdr_color_hover: $main_color;
$ui_select_bdr_color_active: $main_color;

// disabled, readonly
$ui_disabled_bg_color: #EEE;
$ui_disabled_bdr_color: $font_color_exlight;
$ui_disabled_txt_color: $font_color_exlight;


// Cards
// ================================== //

$card_bdr_color: #CCC;
$card_bdr_color_hover: #999;
