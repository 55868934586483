// =====================================================
//
// VARIABLES
//
// =====================================================

// Site config
// ================================== //

// Browser support
$support_regacy_opera: false;

// iOS style
$iOS_form_style_reset: false;

// Use sprite
$use_sprite: false;
$use_sprite_retina: false;

// Use utility css
$use_css_utility: true;


// style config
// ================================== //

// columns
$columns: 12;

// Media Query
$mq-breakpoints: (
  'sp'   : 700px,
  'tab'  : 700px,
  'pc'   : 960px,
  'wide' : 1280px,
);

// Assign Site Width
$site_width: 1200px;
$ct_gutter: 1rem;
$ct_gutter_half: $ct_gutter / 2;

// element margin
$base_mg: $ct_gutter;

// element padding
$base_pd: $ct_gutter;


// Font
// ================================== //

// Base font-size
$font_size: 16;
// Base line-height
$line_height: 1.6;

// Base font-family
$font_family: 'Noto Sans JP', "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Meiryo, sans-serif;
$ff_MP1p: 'M PLUS 1p', sans-serif;
$ff_OpenSans: 'Open Sans', sans-serif;
$ff_YuMincho: "游明朝", "Yu Mincho", YuMincho, "Hiragino Mincho ProN", "HGS明朝E", "メイリオ", Meiryo, serif;
