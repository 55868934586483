/* ===========================================
 Icon Font
=========================================== */
@font-face {
  font-family: "icon";
  src: url( "../fonts/icon.eot?c=1546509751265" );
  src: url( "../fonts/icon.eot?#iefix?c=1546509751265" ) format( "eot" ),
       url( "../fonts/icon.woff?c=1546509751265" ) format( "woff" ),
       url( "../fonts/icon.ttf?c=1546509751265" ) format( "truetype" ),
       url( "../fonts/icon.svg#icon?c=1546509751265" ) format( "svg" );
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  font-family: "icon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-code:before { content: "\F001" }
.icon-external:before { content: "\F002" }
.icon-facebook:before { content: "\F003" }
.icon-github:before { content: "\F004" }
.icon-linkedin:before { content: "\F005" }
.icon-list:before { content: "\F006" }
.icon-qiita:before { content: "\F007" }
.icon-refresh:before { content: "\F008" }
.icon-search:before { content: "\F009" }
.icon-tags:before { content: "\F00A" }
.icon-terminal:before { content: "\F00B" }
.icon-twitter:before { content: "\F00C" }
