//=====================================================
//
// UI Style
//
// =====================================================

// initial
// ================================== //
// タブの数
$tab_length: 5;


// overlay
// ================================== //
.c-overlay {

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(#000, .35);
  transition: all .35s;

  .c-loader {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    display: block;
  }// .c-loader

  @include mq(sp, max, true) {
  }// @include mq(sp, max, true)
  @include mq(tab) {
  }// @include mq(tab)
  @include mq(pc) {
  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .c-overlay


// loading
// ================================== //
.c-loader {
  color: #FFF;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  transform: translateZ(0);
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}// .c-loader

// animation
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// タブ切り替え
// ================================== //
.c-tab {

  .c-tab_list {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    border-bottom: 1px solid #CCC;
    .list_item {
      &:not(:last-child) {
        margin-right: $ct_gutter;
      }// &:not(:first-child)
    }// .list_item
  }// .c-tab_list

  .c-tab_label {
    display: block;
    padding: $ct_gutter_half $ct_gutter;
    border: 1px solid #CCC;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    &:hover {
      background-color: #FFE;
    }// &:hover
  }// .c-tab_label

  .c-tab_ctrl {
    display: none;
  }// .c-tab_ctrl

  .c-tab_panel {
    border: 1px solid #CCC;
    border-top: none;
    .panel_item {
      display: none;
      padding: $ct_gutter;
    }// .panel_item
  }// .c-tab_panel

  $tmp: 0;
  @for $i from 1 through $tab_length {
    @if $i < 10 {
      $tmp: "0#{$i}";
    } @else {
      $tmp: $i;
    }// @if
    #c-tab_ctrl-#{$tmp}:checked {
      ~ .c-tab_list {
        #c-tab_label-#{$tmp} {
          background-color: #FFE;
        }// #c-tab_label-#{$tmp}
      }// ~ .c-tab_head
      ~ .c-tab_panel {
        #c-tab_panel-#{$tmp} {
          display: block;
          @include animation( $name: c-tab_show );
        }// #c-tab_panel-#{$tmp}
      }// ~ .c-tab_panel
    }// #c-tab_ctrl-#{$tmp}:checked
  }// @for

  @include mq(sp, max, true) {
  }// @include mq(sp, max, true)
  @include mq(tab) {
  }// @include mq(tab)
  @include mq(pc) {
  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .c-tab

// animation
@include keyframes(c-tab_show) {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// アコーディオン
// ================================== //
.c-acc {

  border: 1px solid #CCC;
  border-bottom: none;

  .c-acc_label {
    display: block;
    padding: $ct_gutter_half $ct_gutter;
    border-bottom: 1px solid #CCC;
    &:hover {
      background-color: #FFE;
    }// &:hover
  }// .c-acc_label

  .c-acc_ctrl {
    display: none;
  }// .c-acc_ctrl

  .c-acc_contents {
    overflow: hidden;
    max-height: 0;
    transition: all .25s ease-out;
  }// .c-acc_contents

  .c-acc_contents_inner {
    padding: $ct_gutter;
    border-bottom: 1px solid #CCC;
  }// .c-acc_contents_inner

  .c-acc_ctrl:checked {
    ~ .c-acc_label {
      background-color: #FFE;
    }// ~ .c-acc_label
    ~ .c-acc_contents {
      max-height: 1000vh;
      transition: all 1s ease-in;
    }// ~ .c-acc_contents
  }// .c-acc_ctrl:checked

  @include mq(sp, max, true) {
  }// @include mq(sp, max, true)
  @include mq(tab) {
  }// @include mq(tab)
  @include mq(pc) {
  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .c-acc

