// -------------------------------------------
// sass
// -------------------------------------------

@mixin replace($origin, $elem){
  @at-root {
    #{selector-replace(&, $origin, $elem)} {
      @content;
    }
  }// @at-root
}
