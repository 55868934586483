// =====================================================
//
// header Style
//
// =====================================================

// header
// ================================== //
.l-header {

  border-bottom: 2px solid $main_color;

  &-fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    width: 100%;
    padding: 20px;
  }// &-fixed

  .header_ttl {
    font-weight: bold;
    line-height: 1.2;
  }// .header_ttl

  @include mq(sp, max, true) {

    padding: $ct_gutter;

  }// @include mq(sp, max, true)
  @include mq(tab) {

    padding: $ct_gutter ($ct_gutter * 2);

  }// @include mq(tab)
  @include mq(pc) {

    padding-top: $ct_gutter * 1.5;
    padding-bottom: $ct_gutter * 1.5;

  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .l-header
