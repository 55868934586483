// =====================================================
//
// state
//
// =====================================================

.is-active {
}// .is-active

.is-disable {
}// .is-disable

.is-checked {
}// .is-checked

.is-current {
}// .is-current

.is-show {
  display: block;
}// .is-show

.is-hide {
  display: none;
}// .is-hide

.is-opened {
}// .is-opened

.is-closed {
}// .is-closed

.is-odd {
}// .is-odd

.is-even {
}// .is-even
