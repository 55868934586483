//=====================================================
//
// Page Common Style
//
// =====================================================

// Section
// ================================== //
.section {
  @include mq(sp, max, true) {
    + .section {
      margin-top: 2em;
    }// + .section
  }// @include mq(sp, max, true)
  @include mq(tab) {
    + .section {
      margin-top: 3em;
    }// + .section
  }// @include mq(tab)
  @include mq(pc) {
    + .section {
      margin-top: 4em;
    }// + .section
  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .section
