//=====================================================
//
// Title Style
//
// =====================================================

// list
// ================================== //
.c-list {

  &-vertical {
    .c-list_item {
      + .c-list_item {
        margin-top: .875em;
      }// + .c-list_item
    }// .c-list_item
  }// &-vertical

  &-horizonal {
    display: flex;
    flex-wrap: wrap;
    margin: -.25em -.5em;
    .c-list_item {
      // display: inline-block;
      // vertical-align: top;
      margin: .25em .5em;
      white-space: nowrap;
    }// .c-list_item
  }// &-horizonal

  &-disc {
    list-style: disc;
    .c-list_item {
      padding-left: 1.5em;
    }// .c-list_item
  }// &-disc

  &-decimal {
    list-style: decimal;
    .c-list_item {
      padding-left: 1.5em;
    }// .c-list_item
  }// &-decimal

  &-indent {
    .c-list_item {
      text-indent: -1em;
      padding-left: 1em;
    }// .c-list_item
  }// &-indent

  @include mq(tab) {
  }// @include mq(tab)
  @include mq(pc) {

    &-horizonal {
      margin: -.875em;
      .c-list_item {
        margin: .875em;
      }// .c-list_item
    }// &-horizonal

  }// @include mq(pc)
  @include mq(wide) {
  }// @include mq(wide)
}// .c-list
